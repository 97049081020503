import { tv as tvModule } from 'tailwind-variants';

import { twMergeConfig } from '../cn/twMerge';

/**
 * `tv()` is an abstraction of the tailwind-variants package `tv()` function.
 *
 * We are using this abstraction to provide a default `twMergeConfig` to the `tv` function, ensuring our custom `'text-*'` classes are handled correctly.
 *
 * Please refer to the `tailwind-variants` documentation for more information.
 *
 * @param styles - The styles object to be processed by the `tv` function.
 * @param config - The configuration object to be passed to the `tv` function. Note: The `twMergeConfig` is already included in this object, including it again will override the default configuration.
 */
export const tv: typeof tvModule = (styles, config) => tvModule(styles, { twMergeConfig, ...config });
