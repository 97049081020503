import { throwOrConsoleError } from './throwOrConsoleError';

/**
 * Asserts that a condition is true, otherwise throws an error with the given message.
 * This function only throws in non-production environments.
 *
 * @param condition - The condition to assert.
 * @param message - The message to throw if the condition is false.
 */
export function assert(condition: boolean, message: string): asserts condition {
	if (!condition) {
		throwOrConsoleError(message);
	}
}

/**
 * Asserts that a value is defined, otherwise throws an error with the given message.
 * This function only throws in non-production environments.
 *
 * @param value - The value to assert.
 * @param message - The message to throw if the value is undefined.
 */
export const assertIsDefined = <T>(value: T, message: string): asserts value is NonNullable<T> => {
	if (value === undefined || value === null) {
		throwOrConsoleError(message);
	}
};

/**
 * Asserts that a value is a string, otherwise throws an error with the given message.
 * This function only throws in non-production environments.
 *
 * @param value - The value to assert.
 * @param message - The message to throw if the value is not a string.
 */
export const assertIsString = (value: unknown, message: string): asserts value is string => {
	if (typeof value !== 'string') {
		throwOrConsoleError(message);
	}
};

/**
 * Asserts that a value is a number, otherwise throws an error with the given message.
 * This function only throws in non-production environments.
 *
 * @param value - The value to assert.
 * @param message - The message to throw if the value is not a number.
 */
export const assertIsNumber = (value: unknown, message: string): asserts value is number => {
	if (typeof value !== 'number') {
		throwOrConsoleError(message);
	}
};

/**
 * Asserts that a value matches a given type, otherwise throws an error with the given message.
 * This function only throws in non-production environments.
 *
 * @param value - The value to assert.
 * @param type - The type to assert the value against.
 * @param message - The message to throw if the value does not match the type.
 */
export const assertMatchesType = <T extends JsType>(value: unknown, type: T, message: string): asserts value is T => {
	if (typeof value !== typeof type) {
		throwOrConsoleError(message);
	}
};

// possible types in JavaScript
type JsType = 'bigint' | 'boolean' | 'function' | 'number' | 'object' | 'string' | 'symbol' | 'undefined';
