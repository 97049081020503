import merge from 'lodash/merge';

import { CustomTheme, CustomThemeValue, Mode } from './themes.types';

/**
 * Creates a new theme by joining the variables of two themes (for each mode).
 *
 * In case of collisions, the second theme overrides the first.
 *
 *
 * ```ts
 * combineThemes({
 *   dark: {
 *     "--interactive-neutral-regular-color-alpha": 0.95,
 *   },
 *   light: {
 *     "--interactive-neutral-regular-color-rgb": "221,108,108",
 *   },
 * },
 * {
 *   dark: {
 *      "--interactive-neutral-regular-color-rgb": "255,188,129"
 *   },
 *   light: {
 *     "--interactive-neutral-regular-color-alpha": 0.95,
 *     "--interactive-neutral-regular-color-rgb": "221,0,0",
 *   },
 * })
 * ```
 *
 * Would result into
 * ```ts
 * {
 *   dark: {
 *     "--interactive-neutral-regular-color-alpha": 0.95,
 *      "--interactive-neutral-regular-color-rgb": "255,188,129"
 *   },
 *   light: {
 *     "--interactive-neutral-regular-color-alpha": 0.95,
 *     "--interactive-neutral-regular-color-rgb": "221,0,0",
 *   },
 * }
 * ```
 */
export function combineThemes(themeA: CustomTheme, themeB: CustomTheme): CustomTheme {
	return merge(themeA, themeB);
}

export { type CustomTheme, type CustomThemeValue, type Mode };
