import { AssertionError } from './AssertionError';

/** Throws an error in non-production environments, otherwise logs an error to the console. */
export const throwOrConsoleError = (message: string) => {
	if (process.env.NODE_ENV !== 'production') {
		throw new AssertionError(message);
	}

	console.error(`Assertion failed: ${message}`);
};
