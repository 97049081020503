import { extendTailwindMerge, validators } from 'tailwind-merge';

export const textColorValidator = (classname: string): boolean => !classname.startsWith('size');

export const twMergeConfig = {
	override: {
		classGroups: {
			'font-size': [{ 'text-size': [validators.isAny] }],
			'text-color': [
				{
					text: [textColorValidator],
				},
			],
		},
	},
} as const;

export const twMerge = extendTailwindMerge(twMergeConfig);
