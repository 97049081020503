import xxhash from 'xxhashjs';

/**
 * Hashes an object into a unique string
 * @param data - Any JSON object
 */
export function hashObject(data: object): string {
	const str = JSON.stringify(data);
	/* The number is not important, it's just a seed for the hash */
	return xxhash.h32(str, 84251515).toString();
}
