import { useMemo } from 'react';

import { assert } from '../';

/**
 * A hook that returns an object where each value is a function that returns the localized string, with the possibility to pass parameters should the string contain placeholders.
 *
 * Note: The locales object should be a flat object, with each key being a string and each value being a string. Be sure to use the `ComponentLocales<T>` generic to ensure the correct typing.
 *
 * @param locales - The locales object
 * @param defaultValues - The default values
 * @returns The locales object with the default values as fallback, where each value is a function that returns the localized string, with the possibility to pass parameters should the string contain placeholders
 *
 * ```tsx
 * const locales = {
 *  title: "Bonjour, {{name}}",
 *  description: "Comment ça va ?",
 * };
 * const defaultLocalesValues = {
 *  title: "Hello, {{name}}!",
 *  description: "How are you?",
 * };
 *
 * const t = useLocales(locales, defaultValues);
 *
 * t.title({ name: "Change" }); // "Bonjour, Change!"
 * t.description(); // "Comment ça va ?"
 * ```
 */
export const useLocales = <T extends Record<LocaleKey, string>>(
	locales: ComponentLocales<T> | undefined,
	defaultValues: T,
) => {
	const t = useMemo(
		() =>
			// Using a Proxy object rather than fully-recreating the object each time, this allows us to lazy load the values
			new Proxy(defaultValues, {
				get: (target: T, prop: keyof T): LocaleValueFn => {
					const value = locales?.[prop] ?? defaultValues?.[prop];

					return (params = {}) =>
						value.replace(/{{(.*?)}}/g, (_, match: string): string => {
							assert(match in params && params[match] !== undefined, `Missing parameter "${match}" in locales`);

							return params?.[match]?.toString?.();
						});
				},
			}),
		[defaultValues, locales],
	);

	// Typescript does not understand the Proxy object, so we need to cast it to the correct type
	return t as unknown as useLocalesReturnType<T>;
};

type LocaleValueFn = (params?: Record<LocaleKey, number | string>) => string;

type LocaleKey = string | symbol;

type useLocalesReturnType<T extends Record<LocaleKey, string>> = {
	[K in keyof T]: LocaleValueFn;
};

export type ComponentLocales<T extends Record<LocaleKey, string>> = {
	[K in keyof T]?: string;
};
